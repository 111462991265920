<template>
	<div>
		<b-card title="Grup Jenis Pejekerjaan">
			<b-row class="mb-2">
				<b-col cols="12" md="6">
					<b-button
						@click="cleanUpForm()" 
						v-b-modal.form-business-type-modal
						variant="primary"
						class="btn waves-effect waves-float waves-light btn-primary"
					>
						Create Group
					</b-button>
				</b-col>
				<b-col cols="12" md="6">
					<form class="form-inline justify-content-end">
						<div class="d-flex">
							<div class="position-relative">
								<input 
									type="search"
									class="form-control"
									v-model="filter.keyword" 
									placeholder="Search: Keyword" 
									aria-label="Search"
								>
							</div>
						</div>
					</form>
				</b-col>
			</b-row>
			<b-table 
				striped 
				hover 
				:items="businessTypeGroups" 
				:fields="fields"
				:busy="isLoading"
				show-empty
				responsive
			>
				<template #table-busy>
					<div class="text-center text-secondary my-2">
						<b-spinner class="align-middle"></b-spinner>
						<strong>Loading...</strong>
					</div>
				</template>
				
				<template v-slot:cell(no)="{ index }">
					{{ (currentPage - 1) * 10 + index + 1 }}
				</template>

				<template v-slot:cell(actions)="{ item }">
					<div class="d-flex" style="gap:12px;">
						<b-badge 
							variant="warning"
							class="badge-glow"
						>
							<feather-icon
								:id="`invoice-row-${item.uuid}-send-icon`"
								icon="EditIcon"
								class="cursor-pointer"
								size="16"
								v-ripple.400="'rgba(113, 102, 240, 0.15)'"
								v-b-tooltip.hover.bottom="'Edit Data'"
								@click="editBusinessTypeGroup(item.uuid)"
							/>
						</b-badge>
						
						<b-badge 
							variant="danger"
							class="badge-glow"
						>
							<feather-icon
								:id="`invoice-row-${item.uuid}-preview-icon`"
								icon="TrashIcon"
								size="16"
								v-ripple.400="'rgba(113, 102, 240, 0.15)'"
								v-b-tooltip.hover.bottom="'Delete Data'"
								@click="deleteBusinessTypeGroup(item.uuid)"
							/>
						</b-badge>

					</div>
				</template>
			</b-table>
            <b-modal
				id="form-business-type-modal" 
				centered
				:title="editId != null ? 'Edit Group' : 'Add Group'"
				size="lg"
				no-close-on-backdrop
				hide-footer 
			>
				<ValidationObserver>
					<div class="form">
						<div class="form-group">
							<label>Nama Group</label>
							<validation-provider
								name="name"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="text"
									class="form-control"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									placeholder="Nama Group" 
									v-model="formPayload.name"
								>
								<small
									v-for="(validation, index) in validations.name"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group">
							<label>Jenis Pekerjaan</label>
							<validation-provider
								name="partner"
								rules="required"
								v-slot="{ errors}"
							>
								<v-select
									v-model="businessTypeSelected"
									:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
									:state="errors.length > 0 ? false:null"
									label="name"
									multiple
									@search="onSearch"
									placeholder="-- Pilih Jenis Pekerjaan --"
									:options="profession"
								/>
								<small
									v-for="(validation, index) in validations.partner"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
					</div>
					<div v-if="isLoading">
						<br>
						<b-spinner
							class="mb-2"
							variant="primary"
						/><br>
					</div>
					<b-button v-if="editId == null" variant="primary" @click="createItem()" v-bind:disabled="isLoading">
						Save Group
					</b-button>
					<b-button v-else variant="primary" @click="updateItem()" v-bind:disabled="isLoading">
						Edit Group
					</b-button>
				</ValidationObserver>
			</b-modal>
			<div
				v-if="result.total > 0"
				class="m-1">
				<div class="row">
					<div class="col mb-1">
						<small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
					</div>
					<div class="col">
						<pagination
							:data="result"
							:limit="4"
							align="right"
							@pagination-change-page="getData"
						/>
					</div>
				</div>
			</div>
		</b-card>
	</div>
</template>

<script>
import { 
	BCard,
	BCardText,
	BTable,
	VBTooltip,
	BPagination,
	VBModal,
	BButton,
	BSpinner,
	BRow,
	BBadge,
	BCol
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { successNotification, errorNotification } from '@/auth/utils'
import { ValidationProvider, ValidationObserver, configure } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import _ from 'lodash'

configure({
	classes: {
		valid: 'is-valid',
		invalid: 'is-invalid',
		dirty: ['is-dirty', 'is-dirty'], // multiple classes per flag!
		// ...
	}
})
export default {
	components: {
		BCard,
		BCardText,
		BTable,
		BPagination,
		BButton,
		BSpinner,
		BRow,
		BBadge,
		BCol,
		vSelect,
		ValidationProvider, 
		ValidationObserver,
	},
	watch: {
		filter: {
			handler: _.debounce(function () {
				this.getData()
			}, 300),
			deep: true,
		},
	},
	directives: {
    'b-modal': VBModal,
	'b-tooltip': VBTooltip,
		Ripple,
	},
	setup() {
		return {
			successNotification, 
			errorNotification
		}
	},
	data() {
		return {
			required,
			currentPage: 1,
			result: {},
			isLoading: false,
			fields: ['no','name', 'actions'],
			activity: [],
			filter: {
				keyword: '',
			},
			profession: [],
            formPayload: Object,
            businessTypeSelected:[],
            businessTypeGroups: [],
            businessTypes: [],
            editId: null,
			validations: ''
		}
	},
	computed: {
		rows() {
			return this.businessTypeGroups.length
		}
	},

	mounted() {
		this.loadSubprofession()
	},

	methods: {
		title() {
			return `Activity Log`
		},
		modalId(id) {
			return 'modal_' + id;
		},
        getDataByUuid(uuid) {
            this.$http.get('/admin/groups/business-type/' + uuid)
            .then(response => {
                let businessTypeGroup = response.data.data
                this.formPayload = {
                    name: businessTypeGroup.name,
                    items: businessTypeGroup.items,
                }
				this.businessTypeSelected = this.formPayload.items
				console.log(this.formPayload, 'ini data');
			})
        },
		onSearch(search, loading) {
			if (search.length) {
				loading(true);
				this.multipleSelect(loading, search, this);
			} 
			else {
				this.loadSubprofession()
			}
		},

		async loadSubprofession() {
			this.$http.get('admin/fill-questions/compliting-profile/sources/business-types?keyword'
			).then(response => {
				this.profession = response.data.data
			})
		},

		multipleSelect: _.debounce((loading, search, vm) => {
			vm.$http.get('admin/fill-questions/compliting-profile/sources/business-types?keyword='+ search
			).then(response => {
				vm.profession = response.data.data
				loading(false)
			}).catch(() => {
				loading(false)
			})
		}, 300),

		preparePayload() {
			const form = new FormData()
			this.formPayload.business_types = this.businessTypeSelected.map(business => business.uuid)
			for (const key in this.formPayload) {
				if (this.formPayload.hasOwnProperty(key)) {	
					form.append(key, this.formPayload[key])
				}
			}
			if (this.formPayload.business_types) {
				for (let index = 0; index < this.formPayload.business_types.length; index++) {
					const element = this.formPayload.business_types[index];
					form.append('business_types[]', element)
				}
			}
			return form;
		},

		updateItem() {
		var form = this.preparePayload();
			this.isLoading = true
			form.append('_method', 'PATCH');
			this.$http.post(`admin/groups/business-type/${this.editId}`, form, {
				headers: { 'content-type': 'multipart/form-data' }
			})
			.then(response => {
				this.$bvModal.hide('form-business-type-modal')
				this.getData()
				successNotification(this, 'Success', 'List Group Jenis Pekerjaan successfully updated!')
				this.cleanUpForm();
				this.isLoading = false
			})
			.catch(error => {
				if (error.response.data.meta.messages.length > 0) {
				errorNotification(this, 'Oops!', error.response.data.meta.messages)          
				}
				this.isLoading = false
			})
		},

		createItem() {
		var form = this.preparePayload();
			this.isLoading = true
			this.$http.post('admin/groups/business-type', form, {
				headers: { 'Content-Type': 'multipart/form-data' }
			})
			.then(response => {
				this.$bvModal.hide('form-business-type-modal')
				this.getData(this.currentPage)
				successNotification(this, 'Success', 'List Group Jenis Pekerjaan successfully created')
				this.cleanUpForm();
				this.isLoading = false
			})
			.catch(error => {
				if (error.response.data.meta.messages.length > 0) {
				errorNotification(this, 'Oops!', error.response.data.meta.messages)  
				}
				if (error.response.data.meta.validations) {
					this.validations = error.response.data.meta.validations
					errorNotification(this, 'Nama', this.validations.name.join(' '))
				}
				this.isLoading = false
			})
		},

		getData(page) {
			this.isLoading = true
			const queryParams = this.filter
			queryParams.per_page = 10
			queryParams.page = page
			this.$http.get('/admin/groups/business-type', {
				params: queryParams,
			}).then(response => {
				this.result = response.data.data
				this.businessTypeGroups = response.data.data.data
				this.currentPage = response.data.data.current_page
				this.isLoading = false
			})
		},
        cleanUpForm() {
			this.editId = null
			this.formPayload = {
				name: '',
				business_types:[],
			};
			this.businessTypeSelected = []
		},

        editBusinessTypeGroup(uuid) {
            this.editId = uuid
            this.getDataByUuid(uuid)
			this.$bvModal.show('form-business-type-modal')
		},

        deleteBusinessTypeGroup(uuid) {
			this.$swal({
				title: 'Apakah Anda yakin?',
				icon: 'info',
				text: 'Anda akan menghapus Group ini. Data yang sudah terhapus tidak dapat dipulihkan.',
				type: 'warning',
				showCancelButton: true,
				customClass: {
					confirmButton: 'btn btn-primary',
					cancelButton: 'btn btn-outline-danger ml-1',
				},
				confirmButtonText: 'Ya, hapus data ini',
				cancelButtonText: 'Batal',
				buttonsStyling: false
			}).then(async result =>{
				if(result.value) {
					await this.$http.delete('admin/groups/business-type/' + uuid)
					successNotification(this, 'Success', 'Group berhasil dihapus!')
					this.getData()
				}
			}).catch(error => {
				errorNotification(this, 'Oops!', 'Ada Kendala Teknis')
			})
		}
	},
	created() {
		this.getData();
		// this.loadSubprofession();
	}
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
